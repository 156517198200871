'use client'

import { usePathname } from 'next/navigation'
import LogoFooterIcon from '@/app/icons/logo'
import LineFooterIcon from '@/app/icons/lineFooter'
import FacebookFooterIcon from '@/app/icons/facebookFooter'
import CallFooterIcon from '@/app/icons/callFooter'
import Link from 'next/link'
import classNames from 'classnames'
// import TiktokIcon from '../icons/tiktok';5
// import YoutubeFooterIcon from '../icons/youtube';

export default function Footer() {
  const navi = usePathname()
  const menu = [
    {
      title: 'นโยบายความเป็นส่วนตัว',
      path: '/privacy'
    }
  ]
  return (
    <div>
      <div className="bg-gradient-to-b from-primary-ocean-blue-base to-primary-ocean-blue-fate flex items-center justify-center desktop:h-[148px] laptop:h-[116px] tabletHorizontal:h-[152px] mobile:h-[188px]">
        <div className="max-w-[1200px] w-full mx-auto px-">
          <h5 className="text-white-true text-sukhumvit24 font-sukhumvit font-sukhumvitw700 pb-4 w-full text-center">
            ทดลองใช้บริการ Slip2Go หรือมีข้อสงสัยเพิ่มเติม ติดต่อเราได้ทุกเวลา
          </h5>
          <div className="w-ful gap-4 flex justify-center items-center">
            <Link target="_blank" href={process.env.NEXT_PUBLIC_REGISTER || '#'}>
              <h5>
                <button
                  type="button"
                  className="border flex items-center justify-center hover:opacity-90 border-primary-ocean-blue-base text-primary-ocean-blue-base bg-white-true text-sukhumvit16 font-sukhumvit font-sukhumvitw700 rounded-full py-2 desktop:h-10 w-fit laptop:h-[28px] tabletHorizontal:h-[28px] mobile:w-[136px] mobile:h-[28px]"
                >
                  สมัครใช้งานฟรี
                </button>
              </h5>
            </Link>
            <Link
              target="_blank"
              href={'https://line.me/R/ti/p/@199cqywi?from=page&searchId=199cqywi'}
            >
              <h5>
                <button
                  type="button"
                  className="border flex items-center justify-center hover:opacity-70 border-white-true text-white-true bg-transparent text-sukhumvit16 font-sukhumvit font-sukhumvitw700 rounded-full py-2 desktop:w-[139px] desktop:h-10 laptop:w-[99px] laptop:h-[28px] tabletHorizontal:w-[99px] tabletHorizontal:h-[28px] mobile:w-[136px] mobile:h-[28px]"
                >
                  ติดต่อเรา
                </button>
              </h5>
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-[1200px] w-full mx-auto px-2.5">
        <div className="px-4 flex justify-center items-center bg-white bg-opacity-20 border-t border-l-0 border-r-0 border-b-0 border-t-gray-300 border-opacity-25">
          <div className="h-20 w-full flex justify-between items-center">
            <div className="max-w-[420px] w-full gap-4 flex items-center desktop:justify-center justify-center pr-4">
              <div className="pr-4 border-r border-r-neutral-200">
                <LogoFooterIcon classname="w-[127px]" />
              </div>
              <div>
                <Link
                  className="hover:cursor-pointer"
                  target="_blank"
                  href={'https://page.line.me/834kopyd'}
                >
                  <LineFooterIcon />
                </Link>
              </div>
              <div>
                <Link
                  className="hover:cursor-pointer"
                  target="_blank"
                  href={'https://www.facebook.com/people/Slip2Go/61564188032656/'}
                >
                  <FacebookFooterIcon />
                </Link>
              </div>
              <div>
                <Link href={`tel:0902369994`}>
                  <CallFooterIcon />
                </Link>
              </div>
            </div>
            <div className="max-w-full">
              <div className="w-full flex justify-end gap-4">
                {menu.map((m: any, index: number) => {
                  return (
                    <Link
                      href={m.path}
                      key={index}
                      className={classNames(
                        'w-full max-w-fit text-sukhumvit16 font-sukhumvitw700 font-sukhumvit',
                        navi.substring(0, 5) === m.path
                          ? 'text-primary-ocean-blue-600'
                          : 'text-neutral-gray-700 hover:text-primary-ocean-blue-600 hover:cursor-pointer'
                      )}
                    >
                      <h4>{m.title}</h4>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
