'use client'

import classNames from 'classnames'
import { usePathname } from 'next/navigation'

export default function MainLayout({
  propChildren
}: Readonly<{
  propChildren: React.ReactNode
}>) {
  const navi = usePathname()

  return (
    <div className="w-full h-full">
      <div
        className={classNames(
          'desktop:pt-[80px] tablet:pt-[70px] mobile:pt-[70px] labtop:pt-[70px] bg-gradient-to-b from-[#ecf8f7] from-[80%] to-white'
        )}
      >
        <div className={classNames('w-full h-full')}>
          <div
            className={classNames(
              navi === '/service' || navi === '/service' ? 'w-full' : 'w-full h-full'
            )}
          >
            {propChildren}
          </div>
        </div>
      </div>
    </div>
  )
}
