'use client'

import { usePathname } from 'next/navigation'
import Link from 'next/link'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import PageNavMobile from './navMobile'
// import changeUrl from '../utils/cookiesSet';

export default function MenuBar({ locale }: { locale: string }) {
  const navi = usePathname()
  const menu = [
    {
      title: 'หน้าแรก',
      path: '/'
    },
    {
      title: 'บริการของเรา',
      path: '/service'
    },
    {
      title: 'ราคาและแพ็กเกจ',
      path: '/pricing'
    },
    {
      title: 'ข่าวสาร',
      path: '/blog'
    },
    {
      title: 'คำถามที่พบบ่อย',
      path: '/faq'
    },
    {
      title: 'คู่มือการใช้งาน',
      path: '/guide'
    },
    {
      title: 'ติดต่อเรา',
      path: '/about'
    }
  ]
  const [checkClient, setCheckClient] = useState(false)
  useEffect(() => {
    setCheckClient(true)
  }, [])
  useEffect(() => {
    if (checkClient) {
      window.addEventListener('scroll', () => {
        if (window?.process) {
          const element = document.getElementById('nav')
          if (window.scrollY > 40) {
            element?.classList.add('navBg')
          } else {
            element?.classList.remove('navBg')
          }
        }
      })
    }
  }, [checkClient])

  const isPathMatching = (currentPath: string, menuPath: string) => {
    if (menuPath === '/') {
      return currentPath === '/'
    }
    if (
      currentPath === '/service/line-oa' ||
      currentPath === '/service/line-group' ||
      currentPath === '/service/line-1on1' ||
      currentPath === '/service/api-connect'
    ) {
      return '/service' === menuPath
    }
    if (menuPath === '/blog') {
      if (currentPath === '/blog') {
        return true
      }
      if (currentPath.startsWith('/blog/')) {
        return true
      }

      return false
    }

    return currentPath === menuPath
  }
  return (
    <div
      id="nav"
      className="desktop:block w-full top-0 z-40 laptop:block tabletHorizontal:block mobile:block"
    >
      <div className="desktop:block laptop:hidden tabletHorizontal:hidden mobile:hidden">
        <div className="w-full flex items-center justify-evenly">
          <div className="flex w-full items-center justify-evenly">
            <div className="w-[calc(100%-120px)] flex justify-center gap-4">
              {menu.map((m: any, index: number) => {
                return (
                  <Link
                    href={`${m.path}`}
                    key={index}
                    locale={false}
                    className={classNames(
                      'min-w-fit max-w-min font-sukhumvit font-sukhumvitw700 text-sukhumvit16',
                      isPathMatching(navi, m.path)
                        ? 'text-primary-ocean-blue-600'
                        : 'text-neutral-gray-700 hover:text-primary-ocean-blue-600 hover:cursor-pointer'
                    )}
                  >
                    <h5>{m.title}</h5>
                  </Link>
                )
              })}
            </div>
          </div>
          <div className="min-w-fit pr-4 flex items-center">
            <Link
              href={process.env.NEXT_PUBLIC_LOGIN || '#'}
              className="font-sukhumvit font-sukhumvitw700 text-sukhumvit16 text-neutral-gray-700 hover:text-primary-ocean-blue-600"
            >
              <h5>เข้าสู่ระบบ</h5>
            </Link>
          </div>
        </div>
      </div>
      <div className="sticky top-0 z-40 desktop:hidden laptop:block tabletHorizontal:block mobile:block">
        <PageNavMobile locale={locale} />
      </div>
    </div>
  )
}
