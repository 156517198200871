import Image from 'next/image';
import facebookLogo from './facebookFooter.svg';

export default function FacebookFooterIcon() {
  return (
    <div>
      <Image alt="facebookLogo" src={facebookLogo} />
    </div>
  );
}
