import Image from 'next/image';
import lineLogo from './lineFooter.svg';

export default function LineFooterIcon() {
  return (
    <div>
      <Image alt="lineLogo" src={lineLogo} />
    </div>
  );
}
