import Image from 'next/image';
import logo from './logoSliptogo.svg';
import className from 'classnames';

export default function LogoIcon({ classname }: { classname?: string }) {
  return (
    <div className="w-full h-full flex items-center justify-center pl-6">
      <Image
        src={logo}
        alt="logo-slip2go"
        priority // ส่งต่อ priority prop
        className={className("w-full h-full", className)}
        width={110} // กำหนดขนาดที่เหมาะสม
        height={29} // กำหนดขนาดที่เหมาะสม
        quality={85} // เพิ่มคุณภาพรูป
        loading={"eager"} // ใช้ eager loading เมื่อมี priority
        sizes='100px'
      />
    </div>
  );
}
