import Image from 'next/image';
import registerLogo from './registerIcon.svg';

export default function RegisterIcon() {
  return (
    <div>
      <Image alt="registerLogo" src={registerLogo} />
    </div>
  );
}
